import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LookupConfig } from '../../models/lookup-config';
import { LocalCacheService } from './local-cache.service';

@Injectable({
  providedIn: 'root',
})
export class LookupService extends LocalCacheService {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Lookup data from API. Store Data on localcache
   * If offline find in cache.
   */

  async LookupData(lookupConfig: LookupConfig): Promise<any> {
    this.CheckIfOnline();
    if (this.isOnline) {
      let data;
      const params = lookupConfig.urlQryParams;
      await this.http
        .get<any>(environment.urls.apiv1 + lookupConfig.url, { params })
        .toPromise()
        .then((res) => {
          if (lookupConfig.cacheData) {
            this.CacheData(lookupConfig.localIndex, res);
          }
          data = res;
        })
        .catch((e) => console.warn(e));
      return data;
    } else {
      return this.DataLocalCache(lookupConfig.localIndex);
    }
  }
}
