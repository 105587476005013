import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoggingInterceptor } from '../http-interceptors/logging.interceptor';

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoggingInterceptor,
    multi: true,
  },
];
