import { AppDetails } from 'src/app/core/models/newmodels/appdetails.model';
import { v4 as uuidv4 } from 'uuid';
export class LocalCacheService {
  appId = '';
  appDetails: AppDetails;
  isOnline = false;
  constructor() {
    this.appDetails = JSON.parse(
      localStorage.getItem('AppDetails')
    ) as AppDetails;
    this.appId = this.appDetails?.appId;
  }

  CheckIfOnline() {
    const x = JSON.parse(localStorage.getItem('IsOnline')) as boolean;
    this.isOnline = x;
  }
  DataLocalCache(index: string) {
    return JSON.parse(localStorage.getItem(index)) as any[];
  }

  CacheData(index: string, data: any) {
    localStorage.setItem(index, JSON.stringify(data));
  }

  AddDataToCacheOfIndex(index: string, data: any) {
    let existing_data = this.DataLocalCache(index);
    if (!existing_data) {
      existing_data = [];
    }
    if (Array.isArray(data)) {
      data.forEach((element) => {
        element.id = uuidv4.v4();
        existing_data.push(element);
      });
    } else {
      data.id = uuidv4.v4();
      existing_data.push(data);
    }
    localStorage.setItem(index, JSON.stringify(existing_data));
  }

  RemoveDraftFromStorage(index: string, data: any) {
    // Need To test for offline mode
    let existing_data = this.DataLocalCache(index) as any[];
    if (existing_data) {
      existing_data = existing_data.filter((x) => {
        x.id === data.id;
      });
      localStorage.setItem(index, JSON.stringify(existing_data));
    }
  }
}
