import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { of, Subject, BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private title: Subject<string> = new BehaviorSubject('');
  constructor(private docTitle: Title) {}

  setTitle(title: string) {
    if (title) {
      this.title.next(title);
      this.docTitle.setTitle(`${title} - ${environment.applicationName}`);
    }
  }

  getTitle() {
    return this.title;
  }
}
