import { finalize, tap } from 'rxjs/operators';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from 'src/app/_services/global.service';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  constructor(
    private globalService: GlobalService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const started = Date.now();
    let ok: string;

    return next.handle(req).pipe(
      tap(
        (event) => (ok = event instanceof HttpResponse ? 'succeeded' : ''),
        (error) => (ok = 'failed')
      ),
      finalize(() => {
        if (ok === 'succeeded') {
          localStorage.setItem('IsOnline', '1');
          this.globalService.IsOnline = true;
        } else {
          this.globalService.IsOnline = false;
          localStorage.setItem('IsOnline', '0');
          const elapsed = Date.now() - started;
          const msg = `${req.method} "${req.urlWithParams}" ${ok} in ${elapsed} ms.`;
          console.info(msg);
        }
      })
    );
  }

}
