import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { WorkModel } from '../../core/models/work.model';
import { UserJobSignature } from 'src/app/core/models/newmodels/userjob.signatures.model';

@Injectable({
  providedIn: 'root',
})
export class JobDescriptionService {
  constructor(private http: HttpClient) {}

  getWorkTasks() {
    return this.http.get(`${environment.urls.api}/api/work/tasks`);
  }

  getWork(userId: string | number) {
    return this.http.get(`${environment.urls.api}/api/work?user_id=${userId}`);
  }

  createOrUpdate(model: WorkModel) {
    return this.http.post(`${environment.urls.api}/api/work`, model);
  }

  // New
  getUserJobDuties(userId: string, currentUserId: string, type: number = 0) {
    return this.http.get(
      `${environment.urls.api}/api/PwaTask/GetAppClientJobDuties?userId=${userId}&currentUserId=${currentUserId}&type=${type}`
    );
  }
  updateSignature(model: UserJobSignature) {
    return this.http.post(
      `${environment.urls.api}/api/PwaTask/AddSignatureToTask`,
      model
    );
  }
  getAppJobDutyCriteria(appId: string) {
    return this.http.get(
      `${environment.urls.api}/api/AppClientJob/GetAppClientJobDutyCriteriaByAppId?appId=${appId}`
    );
  }

  getAppClientJobDutiesForSignatures(userId: string, currentUserId: string) {
    return this.http.get(
      `${environment.urls.api}/api/PwaTask/GetAppClientJobDutiesForSignatures?userId=${userId}&currentUserId=${currentUserId}`
    );
  }

  addSignatureToDuty(model: any) {
    // not used anymore
    return this.http.post(
      `${environment.urls.api}/api/PwaTask/AddSignatureToDuty`,
      model
    );
  }

  assignDutyToUserWithSignatures(model: any) {
    return this.http.post(
      `${environment.urls.api}/api/PwaTask/assignDutyToUserWithSignatures`,
      model
    );
  }

  clearSignatures(userJobDutyId: string, userId: string) {
    return this.http.get(
      `${environment.urls.api}/api/PwaTask/ClearSignatures?userJobDutyId=${userJobDutyId}&userId=${userId}`
    );
  }
}
