import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserSearchSubmission } from 'src/app/core/models/newmodels/user.search.submission.model';
import { ActionModel } from '../../models/action.model';
import { CorrectionGroupModel } from '../../models/correction-group.model';
import { CorrectionItemModel } from '../../models/correction-item.model';
import { LookupConfig } from '../../models/lookup-config';
import { LookupService } from '../api/lookup.service';
import { AppUserJobDutyViewModel } from '../../models/app-user-job-duty-view.model';
import {
  PWAAppUserPerformanceAssessmentViewModel,
  UserAssessmentTypeEnum,
} from '../../models/app-user-performance-assessment-view.model';
import { AppClientJobDutyCriteriaViewModel } from 'src/app/modules/job-description/models/app-client-job-duty-view.model';
import { WorkTaskModel2 } from 'src/app/core/models/work-task.model';

@Injectable({
  providedIn: 'root',
})
export class LookupRepositoryService {
  constructor(private lookup: LookupService) { }

  async FetchEmployees(): Promise<UserSearchSubmission[]> {
    const localStorageAppDetails = localStorage.getItem('AppDetails');
    const appDetails = JSON.parse(localStorageAppDetails);
    const userId = appDetails?.userId;
    if (userId) {
      const config: LookupConfig = {
        localIndex: 'employees',
        url: '/PWAEmployee/GetEmployeeWorkersByUserId?userId=' + userId,
        cacheData: true,
      };
      const employees = (await this.lookup.LookupData(
        config
      )) as UserSearchSubmission[];
      return employees;
    } else {
      console.warn('FetchEmployees failed due to no user ID');
      return []
    }
  }

  createKeyJobDuties(
    userId: string,
    currentUserId: string,
    type: 0 | 1
  ): string {
    return `job-duties-${userId}${currentUserId}${type}`;
  }
  /**
   *
   * @param userId
   * @param currentUserId
   * @param type Skill - 0; Performance - 0;
   * @returns
   */
  async FetchJobDuties(
    userId: string,
    currentUserId: string,
    type: 0 | 1
  ): Promise<AppUserJobDutyViewModel[]> {
    const params = new HttpParams()
      .set('userId', userId)
      .set('currentUserId', currentUserId)
      .set('type', type.toString());
    const config: LookupConfig = {
      localIndex: this.createKeyJobDuties(userId, currentUserId, type),
      url: '/PwaTask/GetAppClientJobDuties',
      urlQryParams: params,
      cacheData: true,
    };
    const jobDuties = (await this.lookup.LookupData(
      config
    )) as AppUserJobDutyViewModel[];
    return jobDuties;
  }

  createKeyAssesmentDetails(
    userJobId: string,
    type: UserAssessmentTypeEnum
  ): string {
    return `job-duty-assesment-details-${userJobId}${type}`;
  }
  async FetchJobDutyAssessmentDetails(
    userJobId: string,
    type: UserAssessmentTypeEnum
  ): Promise<PWAAppUserPerformanceAssessmentViewModel> {
    const params = new HttpParams()
      .set('userJobId', userJobId)
      .set('type', type.toString());
    const config: LookupConfig = {
      localIndex: this.createKeyAssesmentDetails(userJobId, type),
      url: '/PwaAssessment/GetAssessmentDetails',
      urlQryParams: params,
      cacheData: true,
    };
    const jobDutyAssessmentDetails = (await this.lookup.LookupData(
      config
    )) as PWAAppUserPerformanceAssessmentViewModel;
    return jobDutyAssessmentDetails;
  }

  createKeyJobDutiesForSignatures(
    userId: string,
    currentUserId: string
  ): string {
    return `job-duty-for-signature-${userId}${currentUserId}`;
  }
  async FetchJobDutiesForSignatures(
    userId: string,
    currentUserId: string
  ): Promise<WorkTaskModel2[]> {
    const params = new HttpParams()
      .set('userId', userId)
      .set('currentUserId', currentUserId);
    const config: LookupConfig = {
      localIndex: this.createKeyJobDutiesForSignatures(userId, currentUserId),
      url: '/PwaTask/GetAppClientJobDutiesForSignatures',
      urlQryParams: params,
      cacheData: false,
    };
    const jobDutyForSignatures = (await this.lookup.LookupData(
      config
    )) as WorkTaskModel2[];
    return jobDutyForSignatures;
  }

  createKeyJobDutyCriteria(appId: string): string {
    return `job-duty-criteria-${appId}`;
  }
  async FetchJobDutyCriteria(
    appId: string
  ): Promise<AppClientJobDutyCriteriaViewModel[]> {
    const params = new HttpParams().set('appId', appId);
    const config: LookupConfig = {
      localIndex: this.createKeyJobDutyCriteria(appId),
      url: '/AppClientJob/GetAppClientJobDutyCriteriaByAppId',
      urlQryParams: params,
      cacheData: true,
    };
    const jobDutyCriteria = (await this.lookup.LookupData(
      config
    )) as AppClientJobDutyCriteriaViewModel[];
    return jobDutyCriteria;
  }

  async FetchCorrectionGroups(): Promise<CorrectionGroupModel[]> {
    const localStorageAppDetails = localStorage.getItem('AppDetails');
    const appDetails = JSON.parse(localStorageAppDetails);
    const appId = appDetails?.appId;
    if (appId) {
      const params = new HttpParams().set('appId', appId);
      const config: LookupConfig = {
        localIndex: 'correction-groups',
        url: '/correction/groups',
        urlQryParams: params,
        cacheData: true,
      };
      const groups = (await this.lookup.LookupData(
        config
      )) as CorrectionGroupModel[];
      return groups;
    } else {
      console.warn('FetchCorrectionGroups failed due to no app ID');
      return [];
    }
  }

  async FetchCorrectionGroupItems(
    groupId?: string
  ): Promise<CorrectionItemModel[]> {
    const groupIdExists: boolean = groupId != '' && groupId != undefined;
    const localStorageAppDetails = localStorage.getItem('AppDetails');
    const appDetails = JSON.parse(localStorageAppDetails);
    const appId = appDetails.appId;
    if (appId) {
      let params;
      if (groupIdExists) {
        params = new HttpParams().set('appId', appId).set('groupId', groupId);
      } else {
        params = new HttpParams().set('appId', appId);
      }
      const config: LookupConfig = {
        localIndex: 'correction-group-items',
        url: '/correction/items',
        urlQryParams: params,
        cacheData: !groupIdExists,
      };
      let items = (await this.lookup.LookupData(config)) as CorrectionItemModel[];
      this.lookup.CheckIfOnline();
      if (!this.lookup.isOnline && groupIdExists) {
        items = items.filter((item) => {
          return item.groupId === groupId;
        });
      }
      return items;
    } else {
      console.warn('FetchCorrectionGroupItems failed due to no app ID');
      return [];
    }
  }

  async FetchUserRoles(): Promise<any> {
    const config: LookupConfig = {
      localIndex: 'user-roles',
      url: '/PwaAuth/roles',
    };
    const roles = await this.lookup.LookupData(config);
    return roles;
  }

  async FetchCorrectionGroupItemAction(
    correctionItemId: string,
    employeeId: string
  ): Promise<ActionModel> {
    const params = new HttpParams()
      .set('correctionItemId', correctionItemId)
      .set('employeeId', employeeId);
    const config: LookupConfig = {
      localIndex: `incident-action-${employeeId}-${correctionItemId}`,
      url: '/correction/incidents/action',
      urlQryParams: params,
      cacheData: true,
    };
    const items = (await this.lookup.LookupData(config)) as ActionModel;
    return items;
  }
}
