import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { TitleService } from './shared/services/title.service';
import { NavigationService } from './shared/services/navigation.service';
import { environment } from 'src/environments/environment';
import { LookupRepositoryService } from './shared/services/repository/lookup-repository.service';

@Component({
  selector: 'hs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  sub: any;
  showBtn = false;
  deferredPrompt;
  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    console.log(e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showBtn = true;
  }

  constructor(
    private titleService: TitleService,
    private navigationService: NavigationService,
    private swUpdate: SwUpdate,
    private snackbar: MatSnackBar,
    private router: Router,
    private lookup: LookupRepositoryService,
    private activatedRoute: ActivatedRoute
  ) {
    this.FetchDataForLocalStorage();
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe((evt) => {
        const snack = this.snackbar.open('Update Available', 'Reload', {
          panelClass: ['snack-bar-style'],
        });
        snack.onAction().subscribe(() => {
          window.location.reload();
        });
      });
    }
  }

  ngOnInit(): void {
    try {
      // *
      if (environment.production) {
        if (location.protocol === 'http:') {
          // window.location.href = location.href.replace("http", "https");
        }
      }
      // */

      this.sub = this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map((route) => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          filter((route) => route.outlet === 'primary'),
          mergeMap((route) => {
            return route.data;
          })
        )
        .subscribe((event) => {
          this.titleService.setTitle(event.title);
          this.navigationService.setBackButtonState(event.backButton);
        });
    } catch {
      console.log('error initializing system');
    }
  }

  ngOnDestroy() {
    this.sub && this.sub.unsubscribe();
  }

  add_to_home() {
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      this.deferredPrompt = null;
    });
  }

  FetchDataForLocalStorage() {
    const x = JSON.parse(localStorage.getItem('IsOnline')) as boolean;
    const isOnline: boolean = x;
    if (isOnline) {
      this.lookup.FetchEmployees();
      this.lookup.FetchCorrectionGroups();
      this.lookup.FetchCorrectionGroupItems();
    }
  }
}
