import { AppDetails } from '../core/models/newmodels/appdetails.model';
import { Injectable } from '@angular/core';
import { JobDescriptionService } from '../modules/job-description/job-description.service';
import {
  JobDutyCriteriaModel,
  JobDutyCriteriaModelDraft,
} from '../modules/job-description/models/job-duty-criteria.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UploadSignatureService } from '../modules/upload/services/upload-signature.service';
@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public IsOnline = true;
  private appDetails: AppDetails;
  constructor(private jobService: JobDescriptionService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  getAppConfigs(nav = true) {
    if (this.appDetails === null || this.appDetails == undefined) {
      const localStorageAppDetails = localStorage.getItem('AppDetails');
      if (
        localStorageAppDetails === null ||
        localStorageAppDetails == undefined ||
        localStorageAppDetails === null
      ) {
        // Logout
        // this.snackBar.open('User Expired or is Invalid. Please login again.', 'Ok', { duration: 4000 });
        if (nav) {
          this.router.navigate(['auth', 'login']);
        }
        return;
      } else {
        this.appDetails = JSON.parse(localStorageAppDetails);
      }
    }
    return this.appDetails;
  }
  setAppConfigs(appDetail: AppDetails) {
    localStorage.setItem('AppDetails', JSON.stringify(appDetail));
    this.appDetails = appDetail;
  }
  clearAppConfigs() {
    localStorage.removeItem('AppDetails');
    this.appDetails = null;
  }

  QueueAssesment(model: any, type: string = 'Skill') {
    let currentQueue: any[] = [];
    let currentProblemsJson = localStorage.getItem('QueuedAssesment');
    if (currentProblemsJson === null || currentProblemsJson == undefined) {
    } else {
      currentQueue = JSON.parse(currentProblemsJson) as any[];
    }
    currentQueue.push(model);
    currentProblemsJson = JSON.stringify(currentQueue);
    localStorage.setItem('QueuedAssesment', currentProblemsJson);
    this.snackBar.open(type + 'assesment queued until online', 'Ok', {
      duration: 4000,
    });
  }
}
