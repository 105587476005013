import { Injectable, ErrorHandler, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalService } from 'src/app/_services/global.service';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
  constructor(
    public injector: Injector,
    private globalService: GlobalService
  ) {}

  handleError(error: Error | HttpErrorResponse) {
    const router = this.injector.get(Router);
    const snackBar = this.injector.get(MatSnackBar);
    const ngZone = this.injector.get(NgZone);

    if (error instanceof HttpErrorResponse) {
      // if (connectivity.getConnectionType() === connectivity.connectionType.none) {
      // return alert("No Internet Connection");
      // }

      switch (error.status) {
        case 401:
          // remove(ApplicationConstants.User);
          ngZone.run(() => {
            router.navigate(['/auth/login']);
          });
          return snackBar.open('You are not authorized, please login.', 'Ok', {
            duration: 5000,
          });
        case 404:
          return console.log(`Resource not found ${error.url}`);
        default:
          this.globalService.IsOnline = false;
          return console.log(error.message);
      }
    } else {
      throw error;
    }
  }
}
