<div class="container welcome">
  <div class="welcome__background" [ngClass]="{'welcome__no_code': !canLogIn}"> 
    <div class="welcome__wrapper">
      <div class="welcome__logo">        
        <img src="./assets/images/welcome/sircula_single.png">
      </div>
      <div class="welcome__text">
        <h2 class="welcome__text--small">Welcome To</h2>
        <h1 class="welcome__text--big">Sircula</h1>
      </div>
      <div class="welcome__lines">
        <div class="welcome__line welcome__line--1">

        </div>
        <div class="welcome__line welcome__line--2">

        </div>
        <div class="welcome__line welcome__line--3">

        </div>
      </div>
    </div>
    <!-- <p *ngIf="!canLogIn" class="welcome__more_info">If you need more information n
      about this app, please email Thias Taute at 
      <a href="mailto:thias@sircula.co.za">thias@sircula.co.za</a></p> -->
  </div>

  
  <div class="fixed__buttons">
      <div class="welcome__more_info" *ngIf="!canLogIn">
          URL requires App Code to be able to log in.<br />
          If you need more information n
      about this app, please email Thias Taute at 
      <a href="mailto:thias@sircula.co.za">thias@sircula.co.za</a>
      </div>
      <!-- [routerLink]="['/auth/login']" -->
      <!-- (click)="onLoginClicked()" -->
    <a mat-flat-button color="primary" class="fixed__button" (click)="onLoginClicked()" *ngIf="canLogIn">Login</a>
    <!-- <a mat-flat-button color="primary" class="fixed__button" [routerLink]="['/auth/select-role']">Sign Up</a> -->
  </div>
</div>
