import { GlobalService } from './../../_services/global.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Route } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'hs-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  sub: any;
  code: string;
  canLogIn = true;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService
  ) {}
  appDetails: any;
  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.code = params.get('id');
      if (this.code === null) {
        this.canLogIn = false;
      }
      this.appDetails = this.globalService.getAppConfigs(false);
      if (
        this.appDetails != null &&
        this.appDetails.userId != null &&
        this.appDetails.userId != ''
      ) {
        console.log('navigating to home');
        this.router.navigate(['home', this.code]);
      }
    });
  }

  onLoginClicked() {
    this.router.navigate(['auth/login', this.code]);
  }
}
