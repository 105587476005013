import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  public loader$: EventEmitter<boolean>;

  constructor() {
    this.loader$ = new EventEmitter();
  }

  public Show(): void {
    this.loader$.emit(true);
  }
  public Hide(): void {
    this.loader$.emit(false);
  }
}
