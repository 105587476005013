export class HsSettings {
  public static readonly ALIAS: string = 'hs-web';
  public static readonly STORAGE: string = `${HsSettings.ALIAS}.db`;
  public static readonly TOKEN: string = `${HsSettings.ALIAS}.token`;
  public static readonly USER: string = `${HsSettings.ALIAS}.user`;

  // restricted to ip 41.185.27.100
  public static readonly MAPKEY: string =
    'AIzaSyDHIIWZE9qLNv-1I383hZPEkXiCG2-w7Nc';
  public static readonly DATEFORMAT: string = 'YYYY-MM-DD H:mm:ss';
}
