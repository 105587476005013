import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  hasBackButton: Subject<boolean> = new BehaviorSubject(false);
  constructor() {}

  setBackButtonState(visible: boolean = false) {
    this.hasBackButton.next(visible);
  }
}
